// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/HomeComponents/AboutSection/components/AboutHeader.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/HomeComponents/AboutSection/components/AboutHeader.tsx");
  import.meta.hot.lastModified = "1729806928324.1733";
}
// REMIX HMR END

import AppearWrapper from "../../Animation/Appear.wrapper";
export const AboutHeader = ({
  title,
  subtitle
}) => {
  return <>
      <AppearWrapper delay={0.1}>
        <h1 className="font-extrabold prep-title">{title}</h1>
      </AppearWrapper>
      <AppearWrapper delay={0.2}>
        <h2 className="text-yellow-500 prep-subtitle">{subtitle}</h2>
      </AppearWrapper>
    </>;
};
_c = AboutHeader;
var _c;
$RefreshReg$(_c, "AboutHeader");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;