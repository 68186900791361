// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/components/HomeComponents/PopularCourses/common/ppopular-courses.config.ts"
);
import.meta.hot.lastModified = "1729211991237.1233";
}
// REMIX HMR END

import { FaGraduationCap, FaUniversity, FaLanguage, FaBook } from 'react-icons/fa';
import { MdSchool, MdAssignment } from 'react-icons/md';
import { GiTeacher } from 'react-icons/gi';
import { SiGoogleclassroom } from 'react-icons/si';

// Other
// export const inputStyles = "w-50 mb-2 md:mb-6  px-3 py-2.5 mr-2 leading-tight text-gray-700 bg-white border rounded-lg appearance-none md:px-6 md:py-4 md:mr-4 focus:outline-none text-center font-bold cursor-pointer focus:bg-yellow-50";

// Form configuration
export type ICategories = {
  home: Array<ICategoriesHome>;
}

export type ICategoriesHome = { slug: string ; name: string; icon?: React.ComponentType };

export const categories: ICategories = {
  home: [
    { slug: 'populares', name: 'Populares', icon: FaGraduationCap },
    { slug: 'cursos-admisión', name: 'Cursos de admisión', icon: MdAssignment },
    { slug: 'comipems', name: 'COMIPEMS', icon: MdSchool },
    { slug: 'unam', name: 'UNAM', icon: FaUniversity },
    { slug: 'uam', name: 'UAM', icon: FaUniversity },
    { slug: 'politécnico-nacional', name: 'Politécnico nacional', icon: FaUniversity },
    { slug: 'ingles', name: 'Ingles', icon: FaLanguage },
    { slug: 'cursos-regularizacion', name: 'Cursos de regularizacion', icon: SiGoogleclassroom },
  ]
};
