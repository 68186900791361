// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/HomeComponents/shared/CourseSwiper.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/HomeComponents/shared/CourseSwiper.tsx");
  import.meta.hot.lastModified = "1729806645548.146";
}
// REMIX HMR END

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Controller, EffectFlip, Autoplay } from "swiper/modules";
import AppearWrapper from "../Animation/Appear.wrapper";
import { scaleInVariants } from "~/utils/motion";
const defaultBreakpoints = {
  640: {
    slidesPerView: 5
  }
};
export const CourseSwiper = ({
  images,
  delay = 2500,
  showDetails = false,
  breakpoints = defaultBreakpoints
}) => {
  const swiperStyle = {
    "--swiper-navigation-color": "#fff",
    "--swiper-pagination-color": "#fff"
  };
  return <Swiper style={swiperStyle} autoplay={{
    delay,
    waitForTransition: true,
    disableOnInteraction: true
  }} slidesPerView={2} breakpoints={breakpoints} loop={true} navigation={true} grabCursor={true} pagination={true} modules={[FreeMode, Navigation, Controller, EffectFlip, Autoplay]}>
      {images.map((image, index) => <SwiperSlide key={index}>
          <AppearWrapper delay={Number(`0.${index}`)} variant={scaleInVariants}>
            <div className="relative rounded-2xl h-[40vh] overflow-hidden mx-2">
              {showDetails && image.title && <div className="absolute bottom-0 w-full p-2 pl-4 text-left bg-deep-black/60">
                  <h3 className="font-bold">{image.title}</h3>
                  {image.description && <h6 className="font-medium">{image.description}</h6>}
                </div>}
              <img className="object-cover w-full h-full" src={image.src} alt={image.alt} />
            </div>
          </AppearWrapper>
        </SwiperSlide>)}
    </Swiper>;
};
_c = CourseSwiper;
var _c;
$RefreshReg$(_c, "CourseSwiper");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;