// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/HomeComponents/PopularCourses/index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/HomeComponents/PopularCourses/index.tsx");
  import.meta.hot.lastModified = "1729809140740.3965";
}
// REMIX HMR END

import { getRandomizedCategoryData } from "~/config/covers";
import { CourseSwiper } from "../shared/CourseSwiper";
import { SectionTitle } from "../shared/SectionTitle";
import { SectionHeader } from "../shared/SectionHeader";
import { CategoryForm } from "./components/CategoryForm";
import { useCategory } from "./hooks/useCategory";
import { categories } from "./common/ppopular-courses.config";
import { AnimatePresence, motion } from "framer-motion";
const sliderVariants = {
  enter: {
    opacity: 0,
    y: 20
  },
  center: {
    opacity: 1,
    y: 0
  },
  exit: {
    opacity: 0,
    y: -20
  }
};
const PopularCourses = () => {
  _s();
  const {
    formData,
    handleChange
  } = useCategory();
  const selectedCategory = categories.home.find(cat => cat.slug === formData.selectedCategory);
  const selectedCategoryData = getRandomizedCategoryData(formData.selectedCategory);
  return <div className="border-t bg-gradient-to-b from-zinc-950 border-zinc-800">
      <section id="popular-courses" className="overflow-hidden text-white transition-all">
        <div className="relative w-full mx-auto my-14">
          <SectionHeader title="La cantidad exacta de sabiduría," subtitle="preparada especialmente para ti." />
          <CategoryForm selectedCategory={formData.selectedCategory} onChange={handleChange} />
          <div>
            <div className="container relative z-0 mx-auto">
              <SectionTitle title={selectedCategory?.name || 'Populares'} delay={0.4} />
              <AnimatePresence mode="wait">
                <motion.div key={formData.selectedCategory} initial="enter" animate="center" exit="exit" variants={sliderVariants} transition={{
                duration: 0.3
              }} className="overflow-hidden">
                  <CourseSwiper images={selectedCategoryData} showDetails={true} />
                </motion.div>
              </AnimatePresence>
            </div>
          </div>
        </div>
      </section>
    </div>;
};
_s(PopularCourses, "o+KgjQQCEsCaZ/U0vutsKcsxq1A=", false, function () {
  return [useCategory];
});
_c = PopularCourses;
export default PopularCourses;
var _c;
$RefreshReg$(_c, "PopularCourses");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;