// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/_main._index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/_main._index.tsx");
  import.meta.hot.lastModified = "1729795277387.1682";
}
// REMIX HMR END

import HeroSection from '~/components/HomeComponents/HeroSection';
import AboutSection from '~/components/HomeComponents/AboutSection';
import PopularCourses from '~/components/HomeComponents/PopularCourses';
import CoursesSection from '~/components/HomeComponents/CoursesSection';
import Pricing from '~/components/HomeComponents/Pricing';
import FAQSection from '~/components/HomeComponents/FAQSection';

// export default function CategoryRoute() {
//   return <Outlet />;
// }

export default function HomeRoute() {
  return <>
      <HeroSection></HeroSection>
      <PopularCourses></PopularCourses>
      <CoursesSection></CoursesSection>
      <AboutSection></AboutSection>
      <Pricing></Pricing>
      <FAQSection></FAQSection>
    </>;
}
_c = HomeRoute;
var _c;
$RefreshReg$(_c, "HomeRoute");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;