// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/HomeComponents/Pricing/index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/HomeComponents/Pricing/index.tsx");
  import.meta.hot.lastModified = "1729796585984.2446";
}
// REMIX HMR END

import { useNavigate } from "@remix-run/react";
import { BsDashLg } from "react-icons/bs";
import { BsCheckLg } from "react-icons/bs";
const features = [{
  name: "Más de 10,000 videos pregrabados",
  basic: true,
  premium: true,
  platinum: true
}, {
  name: "Totalmente en línea",
  basic: true,
  premium: true,
  platinum: true
}, {
  name: "Acceso 24/7 a la plataforma",
  basic: true,
  premium: true,
  platinum: true
}, {
  name: "Clases presenciales",
  basic: false,
  premium: true,
  platinum: true
}, {
  name: "Acceso a clases en vivo",
  basic: false,
  premium: false,
  platinum: true
}, {
  name: "Repeticiones de clases en vivo",
  basic: false,
  premium: false,
  platinum: true
}, {
  name: "Duración 6 semanas",
  basic: false,
  premium: false,
  platinum: true
}];
const plans = [{
  name: "Básico",
  price: "$1,500 mxn"
}, {
  name: "Premium",
  price: "$3,000 mxn"
}, {
  name: "Platino",
  price: "$4,500 mxn",
  isHighlighted: true
}];
const FeatureIcon = ({
  isIncluded
}) => <span className={`text-2xl ${isIncluded ? "text-green-400" : "text-red-400"}`}>
    {isIncluded ? <BsCheckLg /> : <BsDashLg />}
  </span>;
_c = FeatureIcon;
const SignUpButton = () => <a target="blank" href="http://localhost:5173/sign-up" className="inline-block px-8 py-2 font-bold text-white bg-yellow-600 rounded-full hover:scale-110 hover:bg-yellow-500 sm:mb-5">
    Inscríbete
  </a>;
_c2 = SignUpButton;
const getFeatureValue = (feature, planName) => {
  switch (planName) {
    case "Básico":
      return feature.basic;
    case "Premium":
      return feature.premium;
    case "Platino":
      return feature.platinum;
    default:
      return false;
  }
};
const MobilePlanCard = ({
  plan,
  features
}) => <div className={`p-6 border border-white/15 rounded-lg ${plan.isHighlighted ? "bg-deep-black/15" : ""}`}>
    <h3 className="mb-2 text-2xl font-bold text-center">{plan.name}</h3>
    <p className="mb-4 text-3xl font-semibold text-center">{plan.price}</p>
    <div className="mb-6 space-y-4">
      {features.map((feature, index) => <div key={index} className="flex items-center space-x-3">
          <FeatureIcon isIncluded={getFeatureValue(feature, plan.name)} />
          <span>{feature.name}</span>
        </div>)}
    </div>
    <div className="text-center">
      <SignUpButton />
    </div>
  </div>;
_c3 = MobilePlanCard;
const Pricing = () => {
  _s();
  const navigate = useNavigate();
  return <div className="border-t bg-gradient-to-b from-stone-900 border-stone-700">
      <div id="pricing-section" className="container flex flex-col items-center py-12 mx-auto mb-24 text-white">
        {/*Pricing desktop*/}
        <div className="container flex flex-col items-center hidden py-12 mx-auto text-white sm:block">
          <div className="mt-8 mb-20">
            <h1 className="block mb-4 text-4xl font-bold text-center sm:text-5xl md:text-7xl">
              Elige el plan ideal para ti
            </h1>
            <h3 className="block mb-8 text-4xl font-medium text-center">
              Opciones flexibles para cada necesidad y presupuesto
            </h3>
          </div>
          <div className="grid w-full max-w-6xl grid-cols-1 gap-6 mx-auto border-b md:grid-cols-2 lg:grid-cols-4 border-white/15">
            <div className="text-center"></div>
            {plans.map((plan, index) => <div key={index} className={`text-center ${plan.isHighlighted ? "bg-deep-black/15" : ""}`}>
                <h3 className="mb-2 text-2xl font-bold">{plan.name}</h3>
                <p className="mb-4 text-3xl font-semibold text-white">{plan.price}</p>
                <SignUpButton />
              </div>)}
          </div>

          <div className="grid w-full max-w-6xl grid-cols-1 gap-6 mx-auto md:grid-cols-2 lg:grid-cols-4">
            <div className="p-6">
              <ul className="space-y-4">
                {features.map((feature, index) => <li key={index} className="flex justify-between">
                    <span>{feature.name}</span>
                  </li>)}
              </ul>
            </div>

            {plans.map((plan, index) => <div key={index} className={`p-6 text-center ${plan.isHighlighted ? "bg-deep-black/15" : ""}`}>
                <ul className="space-y-4">
                  {features.map((feature, index) => <li key={index} className="flex items-center justify-center pt-6">
                      <FeatureIcon isIncluded={getFeatureValue(feature, plan.name)} />
                    </li>)}
                </ul>
              </div>)}
          </div>
        </div>

        {/*mobile*/}
        <div className="container flex flex-col items-center block py-12 mx-auto sm:hidden">
          <h2 className="text-4xl font-semibold text-center mb-14">
            Elige el plan ideal para ti
          </h2>
          
          {/* Mobile Plan Cards */}
          <div className="w-full px-4 space-y-8">
            {plans.map((plan, index) => <MobilePlanCard key={index} plan={plan} features={features} />)}
          </div>
        </div>
      </div>
    </div>;
};
_s(Pricing, "CzcTeTziyjMsSrAVmHuCCb6+Bfg=", false, function () {
  return [useNavigate];
});
_c4 = Pricing;
export default Pricing;
var _c, _c2, _c3, _c4;
$RefreshReg$(_c, "FeatureIcon");
$RefreshReg$(_c2, "SignUpButton");
$RefreshReg$(_c3, "MobilePlanCard");
$RefreshReg$(_c4, "Pricing");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;