// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/HomeComponents/CoursesSection/index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/HomeComponents/CoursesSection/index.tsx");
  import.meta.hot.lastModified = "1729806554692.6252";
}
// REMIX HMR END

import React from "react";
import { CourseSwiper } from "../shared/CourseSwiper";
import { SectionTitle } from "../shared/SectionTitle";
import { coursesData } from "~/config/covers";
const breakpointsCourses = {
  640: {
    slidesPerView: 4
  },
  1024: {
    slidesPerView: 5
  },
  1536: {
    slidesPerView: 6
  }
};
const CoursesSection = () => {
  return <section id="courses-section" className="bg-deep-black">
      <div className="container px-0 mx-auto mb-10">
        <SectionTitle title="Lo más nuevo" delay={0.1} />
        <div className="relative z-0">
          <div className="overflow-hidden">
            <CourseSwiper images={coursesData} delay={3000} breakpoints={breakpointsCourses} />
          </div>
        </div>
      </div>

      <div className="container px-0 mx-auto">
        <SectionTitle title="Cursos de admisión" delay={0.2} />
        <div className="relative z-0">
          <div className="overflow-hidden">
            <CourseSwiper images={coursesData} delay={2600} breakpoints={breakpointsCourses} />
          </div>
        </div>
      </div>

      <div className="container px-0 mx-auto mb-10">
        <SectionTitle title="Cursos de regularización" delay={0.3} />
        <div className="relative z-0">
          <div className="overflow-hidden">
            <CourseSwiper images={coursesData} delay={2600} breakpoints={breakpointsCourses} />
          </div>
        </div>
      </div>
    </section>;
};
_c = CoursesSection;
export default CoursesSection;
var _c;
$RefreshReg$(_c, "CoursesSection");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;