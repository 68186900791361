// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/HomeComponents/PopularCourses/components/CategoryForm.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/HomeComponents/PopularCourses/components/CategoryForm.tsx");
  import.meta.hot.lastModified = "1729808070193.4746";
}
// REMIX HMR END

import { Fragment } from "react";
import { fadeInVariants } from "~/utils/motion";
import AppearWrapper from "../../Animation/Appear.wrapper";
import { categories } from "../common/ppopular-courses.config";
import { RadioInput } from "./RadioInput";
export const CategoryForm = ({
  selectedCategory,
  onChange
}) => {
  return <AppearWrapper delay={0.2} variant={fadeInVariants}>
      <div className="hidden max-w-6xl mx-auto mt-6 text-center sm:block">
        {categories.home.map((element, index) => <Fragment key={`${element.slug}-${index}-f`}>
            <RadioInput key={`${element.slug}-${index}-r`} name={"selectedCategory"} value={element.slug} checked={selectedCategory === element.slug} onChange={onChange} label={element.name} index={index} icon={element.icon} />
          </Fragment>)}
      </div>
    </AppearWrapper>;
};
_c = CategoryForm;
var _c;
$RefreshReg$(_c, "CategoryForm");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;