// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/HomeComponents/FAQSection/index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/HomeComponents/FAQSection/index.tsx");
  import.meta.hot.lastModified = "1729211991236.1233";
}
// REMIX HMR END

import React, { useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import { BsXLg } from "react-icons/bs";
const FAQItem = ({
  question,
  answer,
  isOpen,
  onClick,
  index
}) => {
  return <div className="mx-auto mb-1">
      <button className="w-full flex justify-between items-center py-4 px-6 text-left border border-gray-600 rounded-lg dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-900 transition-all duration-300 ease-in-out" onClick={onClick}>
        <span className="text-lg font-medium text-white">{question}</span>
        <span className="text-2xl text-white">{isOpen ? <BsXLg /> : <BsPlusLg />}</span>
      </button>
      <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isOpen ? "max-h-96 opacity-100" : "max-h-0 opacity-0"}`} style={{
      transform: `translateY(${isOpen ? "0" : "-10px"})`,
      transitionDelay: `${index * 50}ms`
    }}>
        <div className="py-6 px-6 my-2 rounded-lg text-white border border-gray-600 rounded-lg dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-950 transition-all duration-300 ease-in-out">
          <div dangerouslySetInnerHTML={{
          __html: answer
        }} />
        </div>
      </div>
    </div>;
};
_c = FAQItem;
const FAQSection = () => {
  _s();
  const [openItem, setOpenItem] = useState(null);
  const faqData = [{
    question: "¿Qué es una plática informativa?",
    answer: 'En nuestra <strong>PLÁTICA INFORMATIVA GRATUITA</strong> donde podrás: <br><ul class="list-disc ml-5"><li>Aplicar un examen simulacro con preguntas de exámenes anteriores.</li><li>Conocer todo sobre nuestras distintas opciones de preparación: en línea o presencial.</li><li>Conocer la estructura del examen real y todos los detalles de cada etapa del proceso de admisión.</li><li> Además, ¡en todas las pláticas tenemos promociones especiales!</li></ul>'
  }, {
    question: "¿Qué métodos de pago aceptan?",
    answer: 'Contamos con diferentes métodos de pago para que puedas compra el curso que más te guste, puedes realizar el pago a través de:<br><ul class="list-disc ml-5"><li><strong>Tarjetas de débito y crédito</strong></li><li><strong>Transferencia interbancarias</strong></li><li><strong>Depósitos bancarios</strong></li><br>O bien, en cualquiera de nuestras sucursales, aceptamos <strong>pagos en efectivo.</strong>'
  }, {
    question: "¿Cómo hacer mi pago por depósito bancario?",
    answer: '<strong>Realiza tu pago por depósito bancario:</strong><br><ul class="list-disc ml-5"><li>Elige tu curso y tu horario</li><li>Elige pago por depósito bancario y descarga la ficha de pago.</li><li>Acude a una sucursal bancaria.</li><li>Entrega o muestra la ficha de pago y realiza tu pago.</li><li>Anota <strong>NOMBRE COMPLETO Y CORREO DE REGISTRO</strong> del estudiante en el comprobante de pago.</li><li>Envía tu comprobante de pago por correo pagos.preparacion@gmail.com o por Whatsapp al (55) 5563715965.</li></ul>'
  }, {
    question: "¿Cómo puedo hacer mi pago por transferencia?",
    answer: '<strong>Realiza tu pago por transferencia:</strong><br><ul class="list-disc ml-5"><li>Ingresa a tu banca móvil o banca digital.</li><li>Da a alta la cuenta o CLABE:<br>Banco: <strong>BBVA</strong><br>No. de cuenta: <strong>01-9789-6115</strong><br>CLABE: <strong>012180001978961159</strong><br>Concepto: <strong>TU NOMBRE COMPLETO Y EL CURSO QUE ADQUIERES</strong></li><li>Realiza la transferencia por la cantidad exacta.</li><br>Envía tu comprobante de pago por correo pagos.preparacion@gmail.com o por Whatsapp al (55) 5563715965.'
  }];
  const toggleItem = index => {
    setOpenItem(openItem === index ? null : index);
  };
  return <section id="faq-section" className="bg-gradient-to-b from-slate-950 mb-36 bg-transparent border-t border-slate-800">
      <div className="container mx-auto max-w-6xl p-6 rounded-lg">
        <h1 className="text-4xl text-center sm:text-5xl md:text-7xl block mt-20 font-bold">
          Preguntas frecuentes
        </h1>
        <h2 className="text-2xl text-center font-semibold text-white mb-6 px-4 py-6">
          ¿Tienes alguna duda?
        </h2>
        {faqData.map((faq, index) => <FAQItem key={index} question={faq.question} answer={faq.answer} isOpen={openItem === index} onClick={() => toggleItem(index)} index={index} />)}
      </div>
    </section>;
};
_s(FAQSection, "Zmrt7SMlj4FSCEzRBFkAgHFvdlM=");
_c2 = FAQSection;
export default FAQSection;
var _c, _c2;
$RefreshReg$(_c, "FAQItem");
$RefreshReg$(_c2, "FAQSection");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;