// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/HomeComponents/shared/SectionTitle.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/HomeComponents/shared/SectionTitle.tsx");
  import.meta.hot.lastModified = "1729806654149.5283";
}
// REMIX HMR END

import AppearWrapper from "../Animation/Appear.wrapper";
import { fadeInVariants } from "~/utils/motion";
export const SectionTitle = ({
  title,
  delay = 0.1
}) => {
  return <AppearWrapper delay={delay} variant={fadeInVariants}>
      <div className="w-auto px-2 pb-4 text-left">
        <h1 className="inline-block mr-4 text-3xl">{title}</h1>
      </div>
    </AppearWrapper>;
};
_c = SectionTitle;
var _c;
$RefreshReg$(_c, "SectionTitle");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;