// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/HomeComponents/AboutSection/components/InfoCard.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/HomeComponents/AboutSection/components/InfoCard.tsx");
  import.meta.hot.lastModified = "1729806960334.477";
}
// REMIX HMR END

import AppearWrapper from "../../Animation/Appear.wrapper";
import { ArrowIcon } from "./ArrowIcon";
export const InfoCard = ({
  title,
  description,
  href,
  delay
}) => <AppearWrapper delay={delay}>
    <a target="blank" href={href} className="block w-full">
      <div className="max-w-lg p-6 mb-4 transition duration-300 ease-in-out bg-black border border-gray-600 rounded-lg shadow dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-900 hover:scale-105">
        <div className="w-[80%] inline-block align-middle h-full">
          <h3 className="mb-2 text-3xl font-bold tracking-tight text-white prep-cta">
            {title}
          </h3>
          <p className="font-normal text-white">{description}</p>
        </div>
        <div className="w-[20%] inline-block align-middle h-6">
          <div className="items-center text-center transition duration-300">
            <ArrowIcon />
          </div>
        </div>
      </div>
    </a>
  </AppearWrapper>;
_c = InfoCard;
var _c;
$RefreshReg$(_c, "InfoCard");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;