// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/HomeComponents/shared/SectionHeader.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/HomeComponents/shared/SectionHeader.tsx");
  import.meta.hot.lastModified = "1729806754144.8887";
}
// REMIX HMR END

import AppearWrapper from "../Animation/Appear.wrapper";
export const SectionHeader = ({
  title,
  subtitle
}) => {
  return <AppearWrapper delay={0.1}>
      <h1 className="text-2xl text-center sm:text-6xl sm:mb-4">{title}</h1>
      <h3 className="mb-6 text-xl text-center sm:text-4xl">{subtitle}</h3>
    </AppearWrapper>;
};
_c = SectionHeader;
var _c;
$RefreshReg$(_c, "SectionHeader");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;