// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/HomeComponents/PopularCourses/components/RadioInput.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/HomeComponents/PopularCourses/components/RadioInput.tsx");
  import.meta.hot.lastModified = "1729211991237.1233";
}
// REMIX HMR END

import AppearInputWrapper from '../../Animation/AppearInput.wrapper';
import { IoList } from 'react-icons/io5';
export const RadioInput = ({
  name,
  value,
  checked,
  onChange,
  label,
  index,
  isBack,
  icon: Icon
}) => {
  return <AppearInputWrapper delay={Number(`${index}`) / 9}>
      <label className="inline-block font-medium border border-gray-900 hover:scale-110 px-4 py-4 mr-4 mb-2 text-sm md:text-base md:px-4 md:py-3 md:mr-3 md:mb-6 text-white transition-all rounded-2xl bg-gray-900 hover:bg-deep-black duration-300 selection:bg-gray-800 selection:border-yellow-500  has-[:checked]:bg-gray-950 has-[:checked]:border-yellow-500 cursor-pointer">
        <input type="radio" name={name} value={value} checked={checked} onChange={onChange} onClick={onChange} className="appearance-none checked:bg-transparent checked:border-transparent" />
        {isBack && <IoList className="inline-block mr-2" />}
        {Icon && <Icon className="inline-block mr-2" />}
        <div className="inline-block">{label}</div>
      </label>
    </AppearInputWrapper>;
};
_c = RadioInput;
var _c;
$RefreshReg$(_c, "RadioInput");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;