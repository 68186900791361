// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/HomeComponents/HeroSection/index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/HomeComponents/HeroSection/index.tsx");
  import.meta.hot.lastModified = "1729810024268.6965";
}
// REMIX HMR END

import React, { useState } from "react";
import { MdAssignment, MdSchool } from "react-icons/md";
import { FaUniversity } from "react-icons/fa";
import { slideInBlurredFromLeftAndFadeInVariants } from "~/utils/motion";
import { handleScrollHelper } from "~/utils/click-helpers";
import AppearWrapper from "../Animation/Appear.wrapper";
import { APP_URL } from "~/config/environment";
import bgVideo from "~/assets/video/pipnv2-video-bg.mp4";
const HeroSection = () => {
  _s();
  const [email, setEmail] = useState("");
  return <section id="hero-section" className="flex w-full h-screen overflow-hidden align-middle bg-center bg-cover hero-content">
      <div className="absolute z-10 w-full h-screen mx-auto bg-opacity-50 bg-deep-black"></div>
      <video autoPlay loop muted playsInline className="absolute z-0 object-cover w-full h-full ">
        <source src={bgVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="container relative z-20 flex flex-col items-center justify-center min-h-screen mx-auto text-center text-white hero-content">
        <AppearWrapper delay={0} variant={slideInBlurredFromLeftAndFadeInVariants}>
          <h1 className="block text-3xl font-bold sm:mb-4 sm:text-5xl md:text-7xl">
            Domina tu examen de admisión
          </h1>
        </AppearWrapper>

        <AppearWrapper delay={0.4} variant={slideInBlurredFromLeftAndFadeInVariants}>
          <h2 className="block mb-4 text-xl font-medium sm:mb-8 sm:text-4xl">
            Ingresa a la escuela que quieres
          </h2>
        </AppearWrapper>

        <AppearWrapper className="inline-block" delay={0} variant={slideInBlurredFromLeftAndFadeInVariants}>
          <div className="mt-4 mb-10">
            <label className="inline-block px-4 py-1 mb-2 mr-3 font-medium text-white transition-all duration-300 border border-gray-500 rounded-full cursor-pointer bg-deep-black/40 hover:bg-deep-black/80 hover:scale-110 text-md md:text-lg md:px-4 md:mr-5" onClick={e => handleScrollHelper(e, "popular-courses")}>
              <MdAssignment className="inline-block mr-2" />
              <div className="inline-block">Cursos</div>
            </label>

            <label className="inline-block px-4 py-1 mb-2 mr-3 font-medium text-white transition-all duration-300 border border-gray-500 rounded-full cursor-pointer bg-deep-black/40 hover:bg-deep-black/80 hover:scale-110 text-md md:text-lg md:px-4 md:mr-5" onClick={e => handleScrollHelper(e, "about-section")}>
              <MdAssignment className="inline-block mr-2" />
              <div className="inline-block">Informes</div>
            </label>

            <label className="inline-block px-4 py-1 mb-2 mr-3 font-medium text-white transition-all duration-300 border border-gray-500 rounded-full cursor-pointer bg-deep-black/40 hover:bg-deep-black/80 hover:scale-110 text-md md:text-lg md:px-4 md:mr-5" onClick={e => handleScrollHelper(e, "courses-section")}>
              <MdSchool className="inline-block mr-2" />
              <div className="inline-block">Media superior</div>
            </label>

            <label className="inline-block px-4 py-1 mb-2 mr-3 font-medium text-white transition-all duration-300 border border-gray-500 rounded-full cursor-pointer bg-deep-black/40 hover:bg-deep-black/80 hover:scale-110 text-md md:text-lg md:px-4 md:mr-5" onClick={e => handleScrollHelper(e, "courses-section")}>
              <FaUniversity className="inline-block mr-2" />
              <div className="inline-block">Universidades</div>
            </label>
          </div>
        </AppearWrapper>

        <div className="text-xs sm:text-base md:text-lg">
          <AppearWrapper delay={0.6} variant={slideInBlurredFromLeftAndFadeInVariants}>
            <p className="inline-block w-full px-5 mb-3 text-xl text-center sm:px-0 sm:text-base">
              ¿Listo para comenzar? crea tu cuenta con tu correo.
            </p>
            <div className="inline-block w-full text-center">
              <input type="email" name="email" autoComplete="email" className="w-3/4 px-4 py-2 mb-4 text-lg font-medium text-gray-300 transition-all border border-gray-500 rounded-lg sm:mr-5 sm:mb-0 sm:w-96 focus:outline-none focus:ring-2 focus:ring-yellow-500 bg-deep-black/40 hover:bg-deep-black/80 hover:scale-105 sm:text-base" placeholder="Ingresa tu correo" value={email} onChange={e => setEmail(e.target.value)} />
              <a className="inline-block px-4 py-2 text-xl font-medium text-white transition-all bg-yellow-600 rounded-lg select-none hover:bg-yellow-500 hover:text-white hover:scale-105 prep-cta" target="blank" href={`${APP_URL}/sign-up?email=${encodeURIComponent(email)}`}>
                ¡Comenzar ahora!
              </a>
            </div>
          </AppearWrapper>
        </div>
      </div>
    </section>;
};
_s(HeroSection, "Pe1zWWKcsJK82m3WyFiCTurRPxo=");
_c = HeroSection;
export default HeroSection;
var _c;
$RefreshReg$(_c, "HeroSection");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;