// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/components/HomeComponents/AboutSection/config/cards.ts"
);
import.meta.hot.lastModified = "1729806837998.4392";
}
// REMIX HMR END

export const cards = [
  {
    title: "Platica informativa gratuita",
    description: "Aprenderás sobre técnicas, que hay de nuevo con el examen para éste ciclo e información general relacionada a los procesos de admisión.",
    href: ENV.TALKS_REGISTRATIO,
    delay: 0.1,
  },
  {
    title: "Asesorías personalizadas",
    description: "En tus tiempos libres, te ayudamos a encontrar todas las respuestas a tus dudas sobre cualquier materia.",
    href: ENV.ASERORIAS_REGISTRATION,
    delay: 0.2,
  },
  {
    title: "Orientación vocacional",
    description: "Si no estás seguro sobre que carrera elegir, te invitamos a nuestras platicas de orientación vocacional.",
    href: ENV.ORIENTACION_REGISTRATION,
    delay: 0.3,
  },
];
