// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/HomeComponents/AboutSection/index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/HomeComponents/AboutSection/index.tsx");
  import.meta.hot.lastModified = "1729806931323.296";
}
// REMIX HMR END

import React from "react";
import AppearWrapper from "../Animation/Appear.wrapper";
import { InfoCard } from "./components/InfoCard";
import { cards } from "./config/cards";
import { AboutHeader } from "./components/AboutHeader";
const AboutSection = () => {
  return <div className="border-t border-gray-700 bg-gradient-to-b from-gray-900">
      <section id="about-section" className="container flex items-center mx-auto text-white cursor-pointer sm:w-screen sm:h-screen">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="container flex flex-col content-end justify-center mx-auto mt-20 font-prep">
            <AboutHeader title="¿Qué es Más Preparación?" subtitle="Confía en ti, nosotros te apoyamos" />
            <AppearWrapper delay={0.3}>
              <p className="mt-4 mb-8 text-xl leading-relaxed prep-description">
                Durante más de una década, Preparación Politécnico ha
                proporcionado educación de calidad a jóvenes, convirtiéndose en
                un referente en el campo educativo y ayudando a miles de
                estudiantes a alcanzar sus metas y perseguir sus sueños.
              </p>
            </AppearWrapper>
          </div>
          <div className="container flex flex-col items-center content-end h-full mx-auto sm:mt-20">
            {cards.map((card, index) => <InfoCard key={index} title={card.title} description={card.description} href={card.href} delay={card.delay} />)}
          </div>
        </div>
      </section>
    </div>;
};
_c = AboutSection;
export default AboutSection;
var _c;
$RefreshReg$(_c, "AboutSection");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;