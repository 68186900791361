// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/HomeComponents/Animation/AppearInput.wrapper.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/HomeComponents/Animation/AppearInput.wrapper.tsx");
  import.meta.hot.lastModified = "1729211991235.1233";
}
// REMIX HMR END

import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';
import { featureCardAnimateIn } from '~/utils/motion';
function AppearInputWrapper({
  children,
  delay,
  variant,
  threshold
}) {
  _s();
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: false
  });
  useEffect(() => {
    if (inView) {
      controls.start(Object.assign({
        opacity: 1,
        scale: 1,
        y: 0,
        x: 0,
        rotate: 0,
        transition: {
          type: 'spring',
          stiffness: 150,
          delay: delay || 0,
          duration: 0.5,
          threshold: threshold || 0.5
        }
      }, variant?.animate || {}));
    } else {
      controls.start('initial');
    }
  }, [controls, delay, inView, threshold, variant?.animate]);
  return <motion.div ref={ref} variants={featureCardAnimateIn} initial="initial" animate={controls} exit="exit" className="inline-block"
  // whileHover={{
  //   scale: 1.1,
  //   opacity: 1,
  //   transition: { duration: 1 },
  // }}
  // whileTap={{ opacity: 0.9 }}
  >
      {children}
    </motion.div>;
}
_s(AppearInputWrapper, "2NEUvrxzw09dl1eC0Q0pbm0pOIE=", false, function () {
  return [useAnimation, useInView];
});
_c = AppearInputWrapper;
export default AppearInputWrapper;
var _c;
$RefreshReg$(_c, "AppearInputWrapper");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;