// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/config/covers.ts"
);
import.meta.hot.lastModified = "1729809027204.2627";
}
// REMIX HMR END

import universidades from "~/assets/personajes/universidades.jpg";

import feria from "~/assets/events/feria.png";
import halloween from "~/assets/events/haloween.png";
import platica from "~/assets/events/platica.png";

import biologia from "~/assets/covers/biologia.jpg";
import fisica from "~/assets/covers/fisica.jpg";
import ingles from "~/assets/covers/ingles.jpg";
import ipn from "~/assets/covers/ipn.jpg";
import matematicas from "~/assets/covers/matematicas-desde-cero.jpg";
import unam from "~/assets/covers/unam.jpg";

export interface ImageData {
  src: string;
  alt: string;
  title: string;
  description: string;
}

const shuffleArray = <T>(array: T[]): T[] => {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};

export const coursesData: ImageData[] = [
  {
    src: matematicas,
    alt: "Matemáticas desde cero",
    title: "Matemáticas desde cero",
    description: "Domina las matemáticas.",
  },
  {
    src: biologia,
    alt: "Biología",
    title: "Biología",
    description: "Conceptos básicos.",
  },
  {
    src: ingles,
    alt: "Inglés",
    title: "Inglés",
    description: "Aprende inglés fluido.",
  },
  {
    src: fisica,
    alt: "Física",
    title: "Física",
    description: "Fundamentos de física.",
  },
  {
    src: ipn,
    alt: "Admisión IPN",
    title: "Admisión IPN",
    description: "Aprueba el examen IPN.",
  },
  {
    src: unam,
    alt: "Admisión UNAM",
    title: "Admisión UNAM",
    description: "Ingresa a la UNAM.",
  },
  // DUP
  {
    src: matematicas,
    alt: "Matemáticas desde cero",
    title: "Matemáticas desde cero",
    description: "Domina las matemáticas.",
  },
  {
    src: biologia,
    alt: "Biología",
    title: "Biología",
    description: "Conceptos básicos.",
  },
  {
    src: ingles,
    alt: "Inglés",
    title: "Inglés",
    description: "Aprende inglés fluido.",
  },
  {
    src: fisica,
    alt: "Física",
    title: "Física",
    description: "Fundamentos de física.",
  },
  {
    src: ipn,
    alt: "Admisión IPN",
    title: "Admisión IPN",
    description: "Aprueba el examen IPN.",
  },
  {
    src: unam,
    alt: "Admisión UNAM",
    title: "Admisión UNAM",
    description: "Ingresa a la UNAM.",
  },
];

export const getRandomizedCategoryData = (slug: string): ImageData[] => {
  const categoryData = categoriesData[slug];
  return categoryData ? shuffleArray(categoryData) : shuffleArray(coursesData);
};

export const categoriesData: { [key: string]: ImageData[] } = {
  populares: coursesData,
  "cursos-admisión": coursesData,
  comipems: coursesData,
  unam: coursesData,
  uam: coursesData,
  "politécnico-nacional": coursesData,
  ingles: coursesData,
  "cursos-regularizacion": coursesData,
};

export const eventData: ImageData[] = [
  {
    src: halloween,
    alt: "Noche del terror",
    title: "Noche del terror",
    description: "Terror en la noche.",
  },
  {
    src: platica,
    alt: "Plática informativa",
    title: "Plática informativa",
    description: "Infórmate aquí.",
  },
  {
    src: feria,
    alt: "Feria del conocimiento",
    title: "Feria del conocimiento",
    description: "Aprende y explora.",
  },
  {
    src: universidades,
    alt: "Admisión Superior",
    title: "Admisión Superior",
    description: "Universidades te esperan.",
  },
  // DUP
  {
    src: halloween,
    alt: "Noche del terror",
    title: "Noche del terror",
    description: "Terror en la noche.",
  },
  {
    src: platica,
    alt: "Plática informativa",
    title: "Plática informativa",
    description: "Infórmate aquí.",
  },
  {
    src: feria,
    alt: "Feria del conocimiento",
    title: "Feria del conocimiento",
    description: "Aprende y explora.",
  },
  {
    src: universidades,
    alt: "Admisión Superior",
    title: "Admisión Superior",
    description: "Universidades te esperan.",
  },
];
