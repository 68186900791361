// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/components/HomeComponents/PopularCourses/hooks/useCategory.ts"
);
import.meta.hot.lastModified = "1729808081573.8896";
}
// REMIX HMR END

import { useState } from "react";

interface CategoryState {
  selectedCategory: string;
}

const initialState: CategoryState = {
  selectedCategory: "populares",
};

export const useCategory = () => {
  const [formData, setFormData] = useState<CategoryState>(initialState);

  const handleChange = (event: { target: { name: string; value: string } }) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  return {
    formData,
    handleChange,
  };
};
