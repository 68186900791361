// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/config/environment.ts"
);
import.meta.hot.lastModified = "1729809858280.1602";
}
// REMIX HMR END

export const getEnvVar = (key: keyof Window['ENV']) => {
  if (typeof window !== 'undefined') {
    return window.ENV[key];
  }
  return '';
};

export const APP_URL = getEnvVar('APP_URL');
